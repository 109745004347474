import React from "react";
import moment from "moment";
import "./styles.scss";

const ChatMsg = ({ idTicket, ticket }) => {
  return (
    <>
      <div className="content-message-wrapper">
        <div className="content-message-img">
          {ticket.category && ticket.category.idCategory === 1 ? (
            <img
              src={require("../../../../../../assets/img/chat/Parceiro.png")}
              alt=""
            />
          ) : ticket.category.idCategory === 2 ? (
            <img
              src={require("../../../../../../assets/img/chat/ClienteV.png")}
              alt=""
            />
          ) : (
            <img
              src={require("../../../../../../assets/img/chat/ClientePV.png")}
              alt=""
            />
          )}
        </div>
        <div className="content-message-box">
          <div className="content-message-balloon">
            <span className="content-message-client-name">
              {ticket.category.idCategory === 1 ? "Parceiro" : "Cliente"}{" "}
              {ticket.nameCustomer && ticket.nameCustomer} - A resolver
            </span>
            <span className="content-message-text">
              {ticket && ticket.content}
            </span>
            <span className="content-message-date">
              {ticket && ticket.nameUser} |{" "}
              {moment(ticket && ticket.createdAt).calendar()}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatMsg;
