import React from 'react';
import filesize from "filesize";
import { MdCheckCircle, MdError, MdLink } from 'react-icons/md';

import { Container, FileInfo, Preview } from './styles';

const FileList = ({ files, onDelete }) => {
  console.log(files);
  return (
    <Container>
    {files.map((uploadedFile) => (
      <li key={uploadedFile.id || uploadedFile.idTicketImage}>
        <FileInfo>
          <Preview src={uploadedFile.preview || uploadedFile.urlImage} />
          <div>
            <strong>{uploadedFile.name || uploadedFile.originalName}</strong>
            <span>
              {uploadedFile.readableSize || filesize(uploadedFile.size)}{' '}
              <button onClick={(e) => onDelete(e, (uploadedFile.id || uploadedFile.idTicketImage) )}>Excluir</button>
            </span>
          </div>
        </FileInfo>

        <div>
          {(uploadedFile.url || uploadedFile.urlImage) && (
            <a
              href={uploadedFile.url || uploadedFile.urlImage}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
            </a>
          )}

          {uploadedFile.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
          {uploadedFile.error && <MdError size={24} color="#e57878" />}
        </div>
      </li>
    ))}
  </Container>
  );
};

export default FileList;
