import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import "./styles.scss";
import { Row, Col } from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import formatCurrency from "common/formatCurrency";

function Home() {
  const dayToExpire = daysToExpirePoints();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [config, setConfig] = useState({});

  useEffect(() => {
    fetchUser();
  }, []);

  async function fetchUser() {
    setLoading(true);
    try {
      const { data } = await api.get(`/user`);
      setUser(data.data.User);
      setConfig(data.data.Config);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function premiumNotUsed(premiumUsers) {
    if (!premiumUsers || premiumUsers.length === 0) return 0;

    return premiumUsers.filter((x) => !x.dateOfUse).length;
  }

  function daysToExpirePoints() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const firstDayNextMonth = new Date(year, month + 1, 1);

    var differenceInMilliseconds = firstDayNextMonth - now;

    return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  }

  return (
    <Page loading={loading}>
      <Col>
        <Row className="home-row">
          <Col md={5}>
            <div className="home-user">
              <div className="home-user-pic">
                <img src={user.urlUserImage} alt="Avatar do usuário" />
              </div>
              <div className="home-user-info">
                <span className="home-user-name">{user.name}</span>
                <span className="home-user-details">
                  Pontos do mês: <span>{user.monthlyPoints}</span>{" "}
                  {
                    dayToExpire <= 5 &&
                    dayToExpire > 0 &&
                    <span className="text-danger h6">{`(${dayToExpire} dias restantes)`}</span>
                  }
                </span>
                {premiumNotUsed(user.PremiumUsers) > 0 ? (
                  <span
                    className="home-user-redeem"
                    onClick={() => history.push(`/admin/premios-em-espera`)}
                  >
                    Agende seus {premiumNotUsed(user.PremiumUsers)} prêmios{" "}
                    <i className="hasAwards fas fa-trophy" />
                  </span>
                ) : (
                  <span className="home-user-details">
                    Você não possui prêmios em espera
                  </span>
                )}
              </div>
            </div>
          </Col>
          <Col md={2} className="home-comissao">
            <span>Comissão do mês:</span>
            <span>{formatCurrency(user.commissionToReceive)}</span>
          </Col>
          <Col md={2} className="home-metas">
            <span>Meta mensal:</span>
            <span>{formatCurrency(user.monthlyGoal)}</span>
          </Col>
          <Col md={2} className="home-metas">
            <span>Valor vendido:</span>
            <span>{formatCurrency(user.hitGoal)}</span>
          </Col>
        </Row>
        <Row className="home-row mt-4">
          <div className="home-sellers-info">
            <p>
              A bonificação será permitida para os vendedores que passarem a
              meta.
            </p>
            <p>- O valor excedente a meta será convertido em pontos.</p>
            <p>
              - Para cada <span>R$ 100</span> em vendas excedentes, você ganhará{" "}
              <span>
                {config.percentageForPoints}{" "}
                {config.percentageForPoints > 1 ? "pontos" : "ponto"}
              </span>
              .
            </p>
            <p>- Os pontos não serão cumulativos para o próximo mês.</p>
          </div>
        </Row>
      </Col>
    </Page>
  );
}

export default Home;
