import React from "react";

import moment from "moment";

import "./styles.scss";

import { Row, Col, Card } from "reactstrap";

export default function RedeemCard({ premiumUser }) {
  return (
    <Card className="reward-card">
      <div className="reward-img-container">
        <img
          className="reward-img"
          src={premiumUser.Premium.urlPremiumImage}
          alt="Imagem do Prêmio"
        />
      </div>
      <Row>
        <Col className="reward-title">
          <span>{premiumUser.Premium.name}</span>
        </Col>
      </Row>
      <Row>
        <Col className="reward-description">
          <span>{premiumUser.Premium.description}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-1 redeem-date">
            {premiumUser.dateOfUse ? (
              <span>
                Resgatado em{" "}
                {moment(premiumUser.dateOfUse).format("DD/MM/YYYY")}
              </span>
            ) : (
              <span>Prêmio não foi agendado</span>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
}
