import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import NumberFormat from "react-number-format";
import DatePicker from "components/DatePicker";
import PicturesModal from "./PicturesModal";

import { useHistory, useLocation } from "react-router-dom";

import "./styles.scss";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Button,
} from "reactstrap";

import ModalProductNuvemShop from "./ModalProductNuvemShop";
import TableLineProductCart from "./TableLineProductCart";

import states from "common/statesOfBrazil";
import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import formatCurrency from "common/formatCurrency";
import maskCpfCnpj from "common/maskCpfCnpj";
import maskPhone from "common/maskPhone";
import validateCpfCnpj from "common/validateCpfCnpj";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function NewSale() {
  const query = useQuery();
  const idCustomerForm = query.get("idCustomerForm");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [freight, setFreight] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [cep, setCep] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState("");
  const [modalProductNuvemShop, setModalProductNuvemShop] = useState(false);
  const [products, setProducts] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [idPaymentMethod, setIdPaymentMethod] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [idCustomer, setIdCustomer] = useState(query.get("idCustomer"));
  const [birthday, setBirthday] = useState("");
  const [isSedex, setIsSedex] = useState(false);
  const [description, setDescription] = useState("");
  const [haveStock, setHaveStock] = useState(false);
  const [images, setImages] = useState([]);
  const [modalImageOrder, setModalImageOrder] = useState(false);

  useEffect(() => {
    fetchPaymentMethods();
    if (idCustomerForm) fetchCustomerForm();
  }, []);

  useEffect(() => {
    fetchCustomer();
  }, [idCustomer]);

  async function fetchCustomerForm() {
    setLoading(true);
    try {
      const { data } = await api.get(`/customerForm/${idCustomerForm}`);
      setName(data.data.name);
      setEmail(data.data.email);
      setCity(data.data.city);
      setCellphone(data.data.cellphone);
      setCpfCnpj(data.data.cpfCnpj);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function fetchPaymentMethods() {
    setLoading(true);
    try {
      const { data } = await api.get(`/paymentMethod`);
      setPaymentMethods(data.data);
      setIdPaymentMethod(data.data[0].idPaymentMethod);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  useEffect(() => {
    totalCart();
  }, [freight, products, discount]);

  function totalCart() {
    var freightNumber = 0,
      discountNumber = 0;
    if (freight)
      freightNumber = parseFloat(
        isNaN(freight) ? freight.replace(",", ".") : freight
      );

    if (discount)
      discountNumber = parseFloat(
        isNaN(discount) ? discount.replace(",", ".") : discount
      );

    setTotal(freightNumber + totalProducts() - discountNumber);
  }

  function totalProducts() {
    const totalPrice =
      products.length > 0
        ? products
            .map((x) => parseFloat(x.price) * parseInt(x.quantity))
            .reduce((num, total) => num + total)
        : 0;
    return totalPrice;
  }

  async function buscaCep() {
    setLoading(true);
    await axios
      .get(
        `https://viacep.com.br/ws/${cep
          .replace(".", "")
          .replace("-", "")}/json/`
      )
      .then((res) => {
        if (!res.data.localidade) {
          Swal.fire("Erro", "CEP inválido ou não encontrado", "error");
          setLoading(false);
          return;
        }
        setNeighborhood(res.data.bairro);
        setCity(res.data.localidade);
        setProvince(res.data.uf);
        setStreet(res.data.logradouro);
        return;
      })
      .catch(() => {
        Swal.fire("Erro", "CEP inválido ou não encontrado", "error");
        setLoading(false);
        return;
      });
    setLoading(false);
  }

  async function fetchCustomer() {
    setLoading(true);
    const numberCpfCnpj = cpfCnpj.replace(/\D/g, "");
    if (!idCustomer && !numberCpfCnpj) return;

    try {
      var result;
      if (idCustomer) {
        result = await api.get(`/customer/${idCustomer}`);
        setCpfCnpj(result.data.data.cpfCnpj);
      } else result = await api.get(`/customer/cpfCnpj/${numberCpfCnpj}`);

      const data = result.data;
      setIdCustomer(data.data.idCustomer);
      setName(data.data.name);
      setEmail(data.data.email);
      setNeighborhood(data.data.neighborhood);
      setCity(data.data.city);
      setProvince(data.data.province);
      setStreet(data.data.street);
      setNumber(data.data.number);
      setComplement(data.data.complement ? data.data.complement : "");
      setCep(data.data.cep);
      setCellphone(data.data.cellphone);
      setBirthday(new Date(data.data.birthday));
    } catch (error) {
      Swal.fire("Erro", "CPF inválido ou não encontrado", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
  }

  async function saveOrder(event) {
    event.preventDefault();
    switch ("") {
      case name:
        Swal.fire("Atenção", "Nome não foi preenchido", "warning");
        return;
      case email:
        Swal.fire("Atenção", "Email não foi preenchido", "warning");
        return;
      case cpfCnpj:
        Swal.fire("Atenção", "CPF/CNPJ não foi preenchido", "warning");
        return;
      case neighborhood:
        Swal.fire("Atenção", "Bairro não foi preenchido", "warning");
        return;
      case city:
        Swal.fire("Atenção", "Cidade não foi preenchido", "warning");
        return;
      case province:
        Swal.fire("Atenção", "Estado não foi preenchido", "warning");
        return;
      case street:
        Swal.fire("Atenção", "Rua não foi preenchida", "warning");
        return;
      case cep:
        Swal.fire("Atenção", "CEP não foi preenchido", "warning");
        return;
      case cellphone:
        Swal.fire("Atenção", "Telefone não foi preenchido", "warning");
        return;
      case idPaymentMethod:
        Swal.fire(
          "Atenção",
          "Método de pagamento não foi escolhido",
          "warning"
        );
        return;
      case freight:
        Swal.fire("Atenção", "Frete não preenchido", "warning");
        return;
      case birthday:
        Swal.fire(
          "Atenção",
          "Data de nascimento não foi preenchida",
          "warning"
        );
        return;
      case number:
        Swal.fire("Atenção", "Número da casa não foi preenchido", "warning");
        return;
      default:
        break;
    }
    if (products.length === 0 && !products) {
      Swal.fire("Atenção", "Nenhum produto foi inserido", "warning");
      return;
    }

    if (!validateCpfCnpj(cpfCnpj)) {
      Swal.fire("Atenção", "CNPJ ou CPF está inválido, verifique!", "warning");
      return;
    }

    if (complement.length > 30) {
      Swal.fire(
        "Atenção",
        "Complemento excedeu o tamanho permitido. Máximo de 30 carateres.",
        "warning"
      );
      return;
    }

    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente salvar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        const customer = {
          name,
          email,
          cpfCnpj: cpfCnpj.replace(/\D/g, ""),
          neighborhood,
          city,
          province,
          street,
          cep: cep.replace(/\D/g, ""),
          complement,
          cellphone: cellphone.replace(/\D/g, ""),
          number,
          birthday,
        };

        const formData = new FormData();

        for (let i = 0; i < images.length; i++) {
          const image = images[i];
          formData.append("files", image.file);
        }
        formData.append("idCustomer", idCustomer ? idCustomer : "");
        formData.append("haveStock", haveStock);
        formData.append("idPaymentMethod", idPaymentMethod);
        formData.append("isSedex", isSedex);

        var discountNumber = 0;
        if (discount)
          discountNumber = parseFloat(
            isNaN(discount) ? discount.replace(",", ".") : discount
          );

        formData.append("discount", discountNumber);

        var freightNumber = 0;
        if (freight)
          freightNumber = parseFloat(
            isNaN(freight) ? freight.replace(",", ".") : freight
          );

        formData.append("freight", freightNumber);

        formData.append("description", description);
        formData.append("customer", JSON.stringify(customer));
        for (let i = 0; i < products.length; i++) {
          const product = products[i];
          formData.append("products[]", JSON.stringify(product));
        }

        try {
          await api.post(`/order`, formData);

          Swal.fire("Salvo!", "O pedido foi salvo com sucesso", "success");
          history.push("/admin/pedidos");
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page loading={loading}>
      <>
        <ModalProductNuvemShop
          isOpen={modalProductNuvemShop}
          toggle={() => setModalProductNuvemShop(!modalProductNuvemShop)}
          setLoading={setLoading}
          products={products}
          setProducts={setProducts}
        />
        <PicturesModal
          toggleModal={() => setModalImageOrder(!modalImageOrder)}
          isOpen={modalImageOrder}
          setImages={setImages}
          images={images}
        />
      </>
      <Form>
        <Row>
          <Col className="col-4">
            <FormGroup>
              <Label className="form-label" for="inputDoc">
                CPF/CNPJ *
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="far fa-id-card" />
                  </InputGroupText>
                </InputGroupAddon>
                <NumberFormat
                  className="form-input"
                  type="text"
                  maxLength="18"
                  id="inputDoc"
                  placeholder="CPF/CNPJ"
                  format={maskCpfCnpj}
                  customInput={Input}
                  value={cpfCnpj}
                  onChange={(e) => setCpfCnpj(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) fetchCustomer();
                  }}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    className="search-button"
                    onClick={() => fetchCustomer()}
                  >
                    <i className="fas fa-search" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <Label className="form-label" for="inputName">
                Nome *
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="far fa-user" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-input"
                  type="text"
                  id="inputName"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </InputGroup>
              <Label className="form-label" for="inputEmail">
                Email *
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="far fa-envelope" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-input"
                  type="email"
                  id="inputEmail"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
              <Label className="form-label" for="inputTel">
                Telefone *
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-phone" />
                  </InputGroupText>
                </InputGroupAddon>
                <NumberFormat
                  className="form-input"
                  type="text"
                  id="inputTel"
                  placeholder="Telefone"
                  format={maskPhone}
                  customInput={Input}
                  value={cellphone}
                  onChange={(e) => setCellphone(e.target.value)}
                />
              </InputGroup>
              <Label className="form-label">Data de nascimento</Label>
              <DatePicker
                value={birthday}
                onChange={(e) => setBirthday(e._d)}
              />
              <Label className="form-label" for="inputZipcode">
                CEP *
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-location-arrow" />
                  </InputGroupText>
                </InputGroupAddon>
                <NumberFormat
                  className="form-input"
                  type="text"
                  placeholder="CEP"
                  value={cep}
                  onChange={(e) => setCep(e.target.value)}
                  customInput={Input}
                  format="##.###-###"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) buscaCep();
                  }}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    className="table-search-button"
                    onClick={() => buscaCep()}
                  >
                    <i className="fas fa-search" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <Label className="form-label" for="inputAddress">
                Rua *
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-road" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-input"
                  type="text"
                  id="inputAddress"
                  placeholder="Rua"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </InputGroup>
              <Label className="form-label" for="inputNumber">
                Número *
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>₁₂₃</InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-input"
                  type="text"
                  id="inputNumber"
                  placeholder="Número"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </InputGroup>
              <Label className="form-label" for="inputComplement">
                Complemento
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="far fa-building" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  id="inputComplement"
                  placeholder="Complemento"
                  value={complement}
                  onChange={(e) => setComplement(e.target.value)}
                />
              </InputGroup>
              <Label className="form-label" for="inputDistrict">
                Bairro *
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="far fa-map" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-input"
                  type="text"
                  id="inputDistrict"
                  placeholder="Bairro"
                  value={neighborhood}
                  onChange={(e) => setNeighborhood(e.target.value)}
                />
              </InputGroup>
              <Label className="form-label" for="inputCity">
                Cidade *
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-city" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-input"
                  type="text"
                  id="inputCity"
                  placeholder="Cidade"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </InputGroup>
              <Label className="form-label" for="inputState">
                Estado *
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span role="img" aria-label="icone estado">
                      🇸🇵
                    </span>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="province"
                  type="select"
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                >
                  {states.map((state) => (
                    <option key={state.id} value={state.nome}>
                      {state.nome}
                    </option>
                  ))}
                </Input>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col className="col-8">
            <Row form>
              <Col md={6} className="newsale-testa ">
                <FormGroup className="newsale-formgroup">
                  <Label className="form-label" for="inputDesconto">
                    Desconto
                  </Label>
                  <InputGroup className="mb-1">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-dollar-sign" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumberFormat
                      className="form-input"
                      placeholder="Desconto"
                      decimalScale={2}
                      decimalSeparator=","
                      customInput={Input}
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6} className="newsale-testa ">
                <FormGroup className="newsale-formgroup">
                  <Label className="form-label" for="inputFrete">
                    Frete *
                  </Label>
                  <InputGroup className="mb-1">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-shipping-fast" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumberFormat
                      className="form-input"
                      placeholder="Frete"
                      decimalScale={2}
                      decimalSeparator=","
                      customInput={Input}
                      value={freight}
                      onChange={(e) => setFreight(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col className="col-12 newsale-form-payment-price">
                <FormGroup>
                  <Label className="form-label" for="inputDesconto">
                    Forma de envio *
                  </Label>
                  <FormGroup
                    className="d-flex newsale-form-radioform newsale-test"
                    tag="fieldset"
                  >
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="radioSendMode"
                          id="pac-mode"
                          defaultChecked={true}
                          onClick={() => setIsSedex(false)}
                        />
                        PAC
                      </Label>
                    </FormGroup>
                    <FormGroup className="ml-3" check>
                      <Label check>
                        <Input
                          type="radio"
                          name="radioSendMode"
                          id="sedex-mode"
                          onClick={() => setIsSedex(true)}
                        />
                        Sedex
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col className="col-12 newsale-form-payment-price">
                <FormGroup>
                  <Label className="form-label" for="inputDesconto">
                    Forma de pagamento *
                  </Label>
                  <FormGroup
                    className="d-flex newsale-form-radioform newsale-test"
                    tag="fieldset"
                  >
                    {paymentMethods &&
                      paymentMethods.length > 0 &&
                      paymentMethods.map((paymentMethod, index) => (
                        <FormGroup
                          className="ml-3"
                          check
                          key={paymentMethod.idPaymentMethod}
                        >
                          <Label check>
                            <Input
                              type="radio"
                              name="radio1"
                              defaultChecked={index === 0}
                              onClick={() =>
                                setIdPaymentMethod(
                                  paymentMethod.idPaymentMethod
                                )
                              }
                            />
                            {paymentMethod.name}
                          </Label>
                        </FormGroup>
                      ))}
                  </FormGroup>
                </FormGroup>
                <span className="newsale-form-price">
                  {formatCurrency(total)}
                </span>
              </Col>
              <Col className="col-12">
                <FormGroup className="mb-1 w-100 mb-3">
                  <Label className="form-label" for="inputDescricao">
                    Descrição ou personalização (não obrigatório) (máximo 300
                    caracteres)
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="adm-textarea-icon-newsale">
                        <i className="fas fa-file-alt" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <textarea
                      id="inputDescricao"
                      className="form-input adm-textarea-newsale"
                      type="text"
                      maxLength="300"
                      rows="2"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col className="col-12">
                <div className="newsale-adm-preview">
                  {images &&
                    images.length > 0 &&
                    images.map((image) => (
                      <div className="newsale-adm-preview-img">
                        <img
                          src={image.preview}
                          alt="Preview de imagem de referencia para personalização"
                        />
                      </div>
                    ))}
                </div>
              </Col>
              <Col className="d-flex justify-content-end col-12">
                <FormGroup className="newsale-adm-check" check>
                  <Label check>
                    <Input
                      type="checkbox"
                      onClick={() => setHaveStock(!haveStock)}
                    />{" "}
                    Produtos sem estoque
                  </Label>
                </FormGroup>
              </Col>
              <Col className="d-flex justify-content-end col-12 mt-3">
                <Button
                  className="mb-3 text-primary"
                  color="link"
                  onClick={() => setModalImageOrder(true)}
                >
                  Adicionar fotos personalizadas
                </Button>
                <Button
                  className="mb-3"
                  color="primary"
                  onClick={() =>
                    setModalProductNuvemShop(!modalProductNuvemShop)
                  }
                >
                  Adicionar produtos
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  className="d-none d-md-table text-center newsale-products-table"
                  responsive
                >
                  <thead>
                    <tr>
                      <th className="TableTh">Imagem</th>
                      <th className="TableTh text-left">Produto</th>
                      <th className="TableTh">Variação</th>
                      <th className="TableTh">Preço</th>
                      <th className="TableTh">Quantidade</th>
                      <th className="TableTh">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products && products.length > 0 ? (
                      products.map((product) => (
                        <TableLineProductCart
                          key={product.nuvemShopIdVariant}
                          product={product}
                          setProducts={setProducts}
                          totalCart={totalCart}
                        />
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">Nenhum produto foi adicionando...</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
        <Button
          className="float-right mt-3"
          color="primary"
          onClick={(e) => saveOrder(e)}
        >
          Salvar
        </Button>
      </Form>
    </Page>
  );
}
