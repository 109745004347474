import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import "./styles.scss";

import { Row, Col } from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import CustomPagination from "components/CustomPagination";

import RedeemCard from "./RedeemCard";

export default function Redeem() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [premiumUsers, setPremiumUsers] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const limit = 3;

  useEffect(() => {
    fetchPremiumUsers();
  }, [page]);

  async function fetchPremiumUsers() {
    setLoading(true);
    try {
      const { data } = await api.get(`/premiumUser?limit=${limit}&offset=${page - 1}`);
      setPremiumUsers(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  return (
    <Page loading={loading}>
      <>
        <div className="site-button-back">
          <button
            className="button-like-link"
            onClick={() => history.push(`/admin/premios`)}
          >
            <i className="fa fa-arrow-left" /> Voltar
          </button>
        </div>
        <Row>
          <Col>
            <span className="redeem-title">Seus prêmios resgatados:</span>
          </Col>
        </Row>
        <Row>
          {premiumUsers &&
            premiumUsers.length > 0 &&
            premiumUsers.map((premiumUser) => (
              <RedeemCard
                key={premiumUser.idPremiumUser}
                premiumUser={premiumUser}
              />
            ))}
        </Row>
        <div className="d-flex my-3 justify-content-center">
          <CustomPagination
            total={total}
            page={page}
            length={premiumUsers.length}
            limit={limit}
            setPage={setPage}
          />
        </div>
      </>
    </Page>
  );
}
