import React, { useState, useEffect } from "react";
import moment from "moment";

import { useHistory } from "react-router-dom";

import "./styles.scss";
import {
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  Table,
} from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import CustomPagination from "components/CustomPagination";
import TooltipUser from "components/TooltipUser";

export default function Orders() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [idOrderStatus, setIdOrderStatus] = useState("");
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    fetchOrders();
  }, [idOrderStatus]);

  useEffect(() => {
    fetchOrders();
    fetchOrderStatus();
  }, [page]);

  async function fetchOrderStatus() {
    setLoading(true);
    try {
      const { data } = await api.get(`/orderStatus`);
      setOrderStatusList(data.data);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function fetchOrders() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/order?limit=${limit}&offset=${
          page - 1
        }&search=${search}&idOrderStatus=${idOrderStatus}`
      );
      setOrders(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  return (
    <Page loading={loading}>
      <>
        <Row>
          <Form className="col-12">
            <Row>
              <Col md="4">
                <InputGroup>
                  <Input
                    className="table-search-input"
                    placeholder="Buscar..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onClick={() => fetchOrders()}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        fetchOrders();
                      }
                    }}
                  />
                  <InputGroupAddon color="primary" addonType="append">
                    <Button
                      className="table-search-button"
                      onClick={() => fetchOrders()}
                    >
                      <i className="fas fa-search" />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Input
                    type="select"
                    onChange={(e) => setIdOrderStatus(e.target.value)}
                    value={idOrderStatus}
                  >
                    <option key="order-status-0" value="">
                      Todos os pedidos
                    </option>
                    {orderStatusList &&
                      orderStatusList.length > 0 &&
                      orderStatusList.map(({ idOrderStatus, name }) => (
                        <option
                          key={"order-status-" + idOrderStatus}
                          value={idOrderStatus}
                        >
                          {name}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Row>
        <Row>
          <Col>
            <Table
              className="d-none d-md-table sales-orders-table text-center"
              responsive
              striped
            >
              <thead>
                <tr>
                  <th className="TableTh">Pedido</th>
                  <th className="TableTh">Cliente</th>
                  <th className="TableTh w-15">Atendimento</th>
                  <th className="TableTh">Valor</th>
                  <th className="TableTh">Data</th>
                  <th className="TableTh">Situação</th>
                  <th className="TableTh">Ações</th>
                </tr>
              </thead>
              <tbody>
                {orders && orders.length > 0 ? (
                  orders.map(
                    (
                      {
                        idOrder,
                        number,
                        nuvemShopIdOrder,
                        Customer,
                        total,
                        createdAt,
                        OrderStatus,
                        User,
                      },
                      index
                    ) => (
                      <tr key={"order-" + idOrder}>
                        <td className="TableTd">
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`https://prefix7mboots3.lojavirtualnuvem.com.br/admin/orders/${nuvemShopIdOrder}`}
                          >
                            {number} <i className="fas fa-external-link-alt" />
                          </a>
                        </td>
                        <td className="TableTd">{Customer.name}</td>
                        <td className="TableTd">
                          <span
                            className="table-shortname-badge"
                            id={`Tooltip-${User.abbreviation}${index}`}
                          >
                            {User && User.abbreviation}
                            <TooltipUser User={User} Index={index} />
                          </span>
                        </td>
                        <td className="TableTd">
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(total)}
                        </td>
                        <td className="TableTd">
                          {moment(createdAt).format("DD/MM/YYYY")}
                        </td>
                        <td className="TableTd">{OrderStatus.name}</td>
                        <td className="TableTd">
                          <div className="table-action">
                            <div
                              className="table-action-button-edit"
                              onClick={() =>
                                history.push(`/admin/pedido/${idOrder}`)
                              }
                            >
                              <i className="fas fa-eye" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="7">Nenhum registro foi encontrado...</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {
              <div className="d-flex my-3 justify-content-center">
                <CustomPagination
                  total={total}
                  page={page}
                  length={orders.length}
                  limit={limit}
                  setPage={setPage}
                />
              </div>
            }
          </Col>
        </Row>
      </>
    </Page>
  );
}
