import UserProfile from "views/admin/UserProfile";
import Home from "views/admin/Home/index.jsx";
import SalesPanel from "views/admin/SalesPanel";
import NewSale from "views/admin/NewSale/index.jsx";
// Sales
import Customers from "views/admin/Sales/Customers/index.jsx";
import SingleCustomer from "views/admin/Sales/Customers/SingleCustomer/index.jsx";
import Orders from "views/admin/Sales/Orders/index.jsx";
import SingleOrder from "views/admin/Sales/Orders/SingleOrder/index.jsx";
// Site
import Site from "views/admin/Site/index.jsx";
import ClientTable from "views/admin/Site/ClientTable/index.jsx";
// Tickets
import Ticket from "views/admin/Ticket/index.jsx";
import TicketPage from "views/admin/Ticket/TicketPage/index.jsx";
// Premios
import Rewards from "views/admin/Rewards/index.jsx";
import Redeem from "views/admin/Rewards/Redeem/index.jsx";

// System
import Checkout from "views/admin/Checkout/index.jsx";

import NotFound from "views/admin/NotFound";

var routes = [
  {
    path: "/user-profile",
    name: "Perfil do usuário",
    invisible: true,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/index",
    name: "Página inicial",
    icon: "fas fa-home",
    component: Home,
    layout: "/admin",
  },
  {
    path: "/painel-de-vendas",
    name: "Painel de vendas",
    invisible: true,
    component: SalesPanel,
    layout: "/admin",
  },
  {
    path: "/nova-venda",
    name: "Realizar nova venda",
    invisible: true,
    component: NewSale,
    layout: "/admin",
  },

  // // // // // // // // // // // // //
  // Salles //
  {
    collapse: true,
    name: "Vendas",
    icon: "fas fa-shopping-basket",
    state: "salesCollapse",
    views: [
      {
        path: "/clientes",
        sidebarname: "● Clientes",
        component: Customers,
        layout: "/admin",
      },
      {
        path: "/pedidos",
        sidebarname: "● Pedidos",
        component: Orders,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/clientes",
    name: "Tabela de Clientes",
    invisible: true,
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/cliente",
    name: "Visualização de Cliente",
    invisible: true,
    component: SingleCustomer,
    layout: "/admin",
  },
  {
    path: "/pedidos",
    name: "Tabela de Pedidos",
    invisible: true,
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/pedido/:idOrder",
    name: "Visualização de Pedido",
    invisible: true,
    component: SingleOrder,
    layout: "/admin",
  },

  // // // // // // // // // // // // //
  // Site //
  {
    path: "/site",
    name: "Visualização de formulários do site",
    sidebarname: "Site",
    icon: "fas fa-laptop",
    component: Site,
    layout: "/admin",
  },
  {
    path: "/site-form",
    invisible: true,
    component: ClientTable,
    layout: "/admin",
  },
  // TICKETS
  {
    path: "/categorias-tickets",
    name: "Tickets p/ Suporte",
    sidebarname: "Tickets",
    icon: "fas fa-ticket-alt",
    component: Ticket,
    layout: "/admin",
  },
  {
    path: "/tickets/:category",
    name: "Tickets",
    invisible: true,
    component: TicketPage,
    layout: "/admin",
  },
  {
    path: "/tickets/Parceiros",
    name: "Tickets de parceiros",
    invisible: true,
    component: TicketPage,
    layout: "/admin",
  },
  {
    path: "/tickets/Vendas",
    name: "Tickets de vendas",
    invisible: true,
    component: TicketPage,
    layout: "/admin",
  },
  {
    path: "/tickets/Pós-Vendas",
    name: "Tickets de pós-vendas",
    invisible: true,
    component: TicketPage,
    layout: "/admin",
  },
  // PREMIOS
  {
    path: "/premios",
    name: "Resgate de prêmios",
    sidebarname: "Premiação",
    icon: "fas fa-trophy",
    component: Rewards,
    layout: "/admin",
  },
  {
    path: "/premios-em-espera",
    name: "Confira seus prêmios",
    invisible: true,
    component: Redeem,
    layout: "/admin",
  },
  {
    path: "/checkout/:idOrder",
    name: "Checkout do pedido",
    invisible: true,
    component: Checkout,
    layout: "/admin",
  },
  {
    path: "*",
    invisible: true,
    component: NotFound,
    layout: "/admin",
  },
];
export default routes;
