import React from "react";
import moment from "moment";
import "./styles.scss";

const ChatMsg = ({
  idMessage,
  userName,
  content,
  createdAtDate,
  urlUserImage,
}) => {
  return (
    <>
      <div className="message-wrapper">
        <div className="message-box">
          <div className="message-balloon">
            <span className="message-client-name">{userName && userName}</span>
            <span className="message-text">{content && content}</span>
            <span className="message-date">
              {moment(createdAtDate && createdAtDate).calendar()}
            </span>
          </div>
        </div>
        <div className="message-img">
          <div className="message-img-wrapper">
            <img
              src={urlUserImage && urlUserImage}
              alt={userName && userName}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatMsg;
